import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ExampleOptIn = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <div className="max-w-md mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">SMS Notifications Opt-In</h1>

      <div className="mb-6">
        <p className="text-gray-600 mb-4">
          To receive SMS notifications from SpendoTracker, please review and consent to our terms below.
        </p>

        <div className="flex items-start mb-4">
          <div className="flex items-center h-5">
            <input
              id="sms-consent"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
            />
          </div>
          <label htmlFor="sms-consent" className="ml-2 text-sm text-gray-600">
            I consent to receive SMS notifications from SpendoTracker and agree to the{' '}
            <Link to="/privacy-policy" className="text-blue-600 hover:underline">
              Privacy Policy
            </Link>
            . I understand that message and data rates may apply and that I can opt-out at any time by replying STOP to any message.
          </label>
        </div>
      </div>

      <button
        disabled={!isChecked}
        className={`w-full py-2 px-4 rounded ${isChecked
          ? 'bg-blue-600 hover:bg-blue-700 text-white'
          : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
      >
        Enable SMS Notifications
      </button>
    </div>
  );
};

export default ExampleOptIn;