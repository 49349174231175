import React, { useState, useEffect } from "react"
import BudgetList from '../components/BudgetList'
import BudgetForm from "../components/BudgetForm";
import { useUser } from "../store/UserContext";
import { readBudgets, deleteBudget } from "../network/budget";

export default function Budgets() {
  const user = useUser()
  const [showForm, setShowForm] = useState(false)
  const [budgets, setBudgets] = useState([])
  const [selectedBudget, setSelectedBudget] = useState(null)

  useEffect(() => {
    const { id, token } = user

    const fetchBudgets = async ({ user_id, token }) => {
      const { data } = await readBudgets({ user_id, token })
      setBudgets([...data.budgets])
    }

    fetchBudgets({ user_id: id, token })
  }, [user])

  const handleDeleteBudget = async (budgetId) => {
    try {
      await deleteBudget({ user_id: user.id, token: user.token, budget_id: budgetId });
      setBudgets(budgets.filter(budget => budget._id !== budgetId));
      if (selectedBudget && selectedBudget._id === budgetId) {
        setSelectedBudget(null);
      }
    } catch (error) {
      console.error("Error deleting budget:", error);
    }
  }

  return (
    <div>
      <div className="mt-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {showForm ? (
          <BudgetForm
            setShowForm={setShowForm}
            selectedBudget={selectedBudget}
            setSelectedBudget={setSelectedBudget}
            budgets={budgets}
            setBudgets={setBudgets}
          />
        ) : (
          <BudgetList
            setShowForm={setShowForm}
            setSelectedBudget={setSelectedBudget}
            budgets={budgets}
            onDeleteBudget={handleDeleteBudget}
          />
        )}
      </div>
    </div>
  )
}
