import {
  usePlaidLink,
} from 'react-plaid-link';

import React, { useState, useEffect } from "react"
import { useUser, useUserDispatch } from "../store/UserContext";
import { base_url } from "../network";
import { handleCreateBankLink, handleSetAuthRefresh } from '../services/bank';

const getConfig = (setShowForm, dispatch, user_id, token, linkToken, bank_id = null) => {
  return {

    // in metadata we get accounts:
    // metadata.accounts
    // from there render data to user and ask if they want to save link

    onSuccess: (public_token, metadata) => {
      console.log('onSuccess')
      console.log('public_token: ', public_token);
      console.log('metadata: ', metadata);
      console.log('account nae: ', metadata?.institution?.name);
      if (bank_id) {
        handleSetAuthRefresh({
          user_id,
          bank_id,
          token,
          dispatch
        })
      } else {
        const bank_accounts = [...metadata?.accounts.map(a => {
          return {
            ...a,
            account_id: a.id,
          }
        }
        )]
        console.log({ bank_accounts })
        handleCreateBankLink({
          user_id,
          public_token,
          bank_name: metadata?.institution?.name,
          bank_accounts,
          token,
          dispatch,
          bank_id: metadata?.account_id
        })
      }
      setShowForm(false);
    },
    onExit: (err, metadata) => {
      console.log('onExit')
      console.log('err: ', err);
      console.log('metadata: ', metadata);
      setShowForm(false);
    },
    onEvent: (eventName, metadata) => {
      console.log('onEvent: ', eventName)
      console.log('metadata: ', metadata);
    },
    token: linkToken
    //required for OAuth; if not using OAuth, set to null or omit:
  }
};

const generateLink = async (setLinkToken, user, access_token = null) => {
  console.log('THIS ONE IS RUNNING!!!!')
  const { id, token } = user;
  console.log('id', id, 'token', token)

  try {
    const response = await fetch(
      // base_url + '/api/plaid/create_link', 
      'http://localhost:8000/dev/plaid/createLink',
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          user_id: id,
          access_token
        })
      })

    if (!response.ok) {
      throw new Error(response?.statusText)
    }

    const { data } = await response.json();
    console.log('generateLink response', data);
    setLinkToken(data?.link_token);

  } catch (error) {
    console.log('error generating link', error)
    setLinkToken('unable to set link token')

  }
}


export default function NewBankForm(props) {
  const user = useUser();
  const dispatch = useUserDispatch();
  const [linkToken, setLinkToken] = useState(null);
  const [accessToken] = useState(props?.accessToken || null)
  // const [transactions, setTransactions] = useState([]);

  const { id, token } = user;
  const config = getConfig(props?.setShowForm, dispatch, id, token, linkToken, props?.bank_id);
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    const initGenerateLink = async () => {
      await generateLink(setLinkToken, user, accessToken)
    }

    initGenerateLink();
  }, [accessToken, user])

  useEffect(() => {
    if (!ready) {
      console.log('not ready')
      return;
    }

    try {
      open()
    } catch (error) {
      alert('Error Linking Bank')
    }
  }, [open, ready])


  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   //const {name, recipients, active, definition} = notificationData;
  //   const {id, token} = user;
  //   // to do get auth context here to authenticat user in api call
  //     // createNotification({name, recipients, definition, active, userId: id, token}).then(res => {
  //       if(res){
  //         // authContext.setCurrentUser({
  //         //   ...authContext.currentUser,
  //         //   notifications: [...res?.notifications]
  //         // })
  //         props.setShowForm(false);
  //       }
  //      // })
  // }

  return (
    <div>form</div>
    // <form onSubmit={() => {}} className="space-y-8 divide-y divide-gray-200">
    //   <div className="space-y-8">
    //     <div className="pt-8">
    //       <div>
    //         <h3 className="text-lg font-medium leading-6 text-gray-900">Link New Bank</h3>
    //         <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can receive mail.</p>
    //       </div>
    //       <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
    //         <div className="sm:col-span-4">
    //           <label htmlFor="notification-name" className="block text-sm font-medium text-gray-700">
    //             Notification name
    //           </label>
    //           <div className="mt-1">
    //             <input
    //               id="notification-name"
    //               name="name"
    //               onChange={handleChange}
    //               value={notificationData.name}
    //               type="text"
    //               className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
    //             />
    //           </div>
    //         </div>

    //         <div className="sm:col-span-4">
    //           <label htmlFor="recipients" className="block text-sm font-medium text-gray-700">
    //             Recipients 
    //           </label>
    //           <div className="mt-1">
    //             <input
    //               id="recipients"
    //               name="recipients"
    //               onChange={handleChange}
    //               value={notificationData.recipients}
    //               type="text"
    //               className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
    //             />
    //           </div>
    //         </div>

    //         <div className="sm:col-span-4">
    //           <label htmlFor="definition" className="block text-sm font-medium text-gray-700">
    //             Definition 
    //           </label>
    //           <div className="mt-1">
    //             <input
    //               id="definition"
    //               name="definition"
    //               onChange={handleChange}
    //               value={notificationData?.definition}
    //               type="text"
    //               className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //       <div className="mt-6">
    //         <fieldset className="mt-6">
    //           <legend className="contents text-base font-medium text-gray-900">Set Active</legend>
    //           <p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p>
    //           <div className="mt-4 space-y-4">
    //             <div className="flex items-center">
    //               <input
    //                 id="active-true"
    //                 name="active"
    //                 type="checkbox"
    //                 onChange={handleChange}
    //                 value={notificationData?.active}
    //                 className="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
    //               />
    //               <label htmlFor="active-true" className="ml-3 block text-sm font-medium text-gray-700">
    //                  Active 
    //               </label>
    //             </div>
    //           </div>
    //         </fieldset>
    //       </div>
    //     {/* <div className="pt-8">
    //       <div>
    //         <h3 className="text-lg font-medium leading-6 text-gray-900">Notifications</h3>
    //         <p className="mt-1 text-sm text-gray-500">
    //           We'll always let you know about important changes, but you pick what else you want to hear about.
    //         </p>
    //       </div>
    //       <div className="mt-6">
    //         <fieldset>
    //           <legend className="sr-only">By Email</legend>
    //           <div className="text-base font-medium text-gray-900" aria-hidden="true">
    //             By Email
    //           </div>
    //           <div className="mt-4 space-y-4">
    //             <div className="relative flex items-start">
    //               <div className="flex h-5 items-center">
    //                 <input
    //                   id="comments"
    //                   name="comments"
    //                   type="checkbox"
    //                   className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
    //                 />
    //               </div>
    //               <div className="ml-3 text-sm">
    //                 <label htmlFor="comments" className="font-medium text-gray-700">
    //                   Comments
    //                 </label>
    //                 <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p>
    //               </div>
    //             </div>
    //             <div className="relative flex items-start">
    //               <div className="flex h-5 items-center">
    //                 <input
    //                   id="candidates"
    //                   name="candidates"
    //                   type="checkbox"
    //                   className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
    //                 />
    //               </div>
    //               <div className="ml-3 text-sm">
    //                 <label htmlFor="candidates" className="font-medium text-gray-700">
    //                   Candidates
    //                 </label>
    //                 <p className="text-gray-500">Get notified when a candidate applies for a job.</p>
    //               </div>
    //             </div>
    //             <div className="relative flex items-start">
    //               <div className="flex h-5 items-center">
    //                 <input
    //                   id="offers"
    //                   name="offers"
    //                   type="checkbox"
    //                   className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
    //                 />
    //               </div>
    //               <div className="ml-3 text-sm">
    //                 <label htmlFor="offers" className="font-medium text-gray-700">
    //                   Offers
    //                 </label>
    //                 <p className="text-gray-500">Get notified when a candidate accepts or rejects an offer.</p>
    //               </div>
    //             </div>
    //           </div>
    //         </fieldset>
    //         <fieldset className="mt-6">
    //           <legend className="contents text-base font-medium text-gray-900">Push Notifications</legend>
    //           <p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p>
    //           <div className="mt-4 space-y-4">
    //             <div className="flex items-center">
    //               <input
    //                 id="push-everything"
    //                 name="push-notifications"
    //                 type="radio"
    //                 className="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
    //               />
    //               <label htmlFor="push-everything" className="ml-3 block text-sm font-medium text-gray-700">
    //                 Everything
    //               </label>
    //             </div>
    //             <div className="flex items-center">
    //               <input
    //                 id="push-email"
    //                 name="push-notifications"
    //                 type="radio"
    //                 className="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
    //               />
    //               <label htmlFor="push-email" className="ml-3 block text-sm font-medium text-gray-700">
    //                 Same as email
    //               </label>
    //             </div>
    //             <div className="flex items-center">
    //               <input
    //                 id="push-nothing"
    //                 name="push-notifications"
    //                 type="radio"
    //                 className="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
    //               />
    //               <label htmlFor="push-nothing" className="ml-3 block text-sm font-medium text-gray-700">
    //                 No push notifications
    //               </label>
    //             </div>
    //           </div>
    //         </fieldset>
    //       </div>
    //     </div> */}
    //   </div>

    //   <div className="pt-5">
    //     <div className="flex justify-end">
    //       <button
    //         onClick={() => props?.setShowForm(false)}
    //         type="button"
    //         className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
    //       >
    //         Cancel
    //       </button>
    //       <button
    //         type="submit"
    //         className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
    //       >
    //         Save
    //       </button>
    //     </div>
    //   </div>
    // </form>
  )
}
