import { base_url } from ".";
const URL = `${base_url}/api/recipient`;

const createRecipient = async ({ name, cell, email, user_id, token }) => {
  const response = await fetch(
    //  `${URL}/create`
    'http://localhost:8000/dev/recipient/create'
    , {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
      body: JSON.stringify({
        user_id,
        name,
        cell,
        email
      })
    });
  return await response.json();
}
const updateRecipient = async ({ recipient_id, name, cell, email, user_id, token }) => {
  const response = await fetch(`${URL}/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token

    },
    body: JSON.stringify({
      user_id,
      name,
      cell,
      email,
      recipient_id
    })
  });
  return await response.json();
}
const deleteRecipient = async ({ recipient_id, user_id, token }) => {
  const response = await fetch(`${URL}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token

    },
    body: JSON.stringify({
      user_id,
      recipient_id
    })
  });

  if (!response.ok) throw new Error('Error deleting recipient')

  return await response.json();
}

export { createRecipient, deleteRecipient, updateRecipient }