import React, { useEffect } from "react";
import { useUser } from "../store/UserContext";
import { useNavigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    if (!user?.token) {
      navigate('/signin', { replace: true });
    }
  }, [user, navigate]);

  return children;
}
export default RequireAuth