import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <div className="prose prose-lg">
        <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>

        <p className="italic mb-8">Last updated: December 9, 2023</p>

        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <p className="mb-6">Welcome to SpendoTracker ("we," "our," or "us"). We respect your privacy and are committed to protecting your personal data. This privacy policy explains how we handle your information when you use our financial notification service.</p>

        <h2 className="text-2xl font-semibold mb-4">2. Information We Collect</h2>
        <p className="mb-2">We collect and process the following information:</p>
        <ul className="list-disc pl-6 mb-6">
          <li>Phone numbers for sending notifications</li>
          <li>Banking account balances and transaction data through Plaid's secure services</li>
          <li>Basic user account information</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Information</h2>
        <p className="mb-2">We use your information solely to:</p>
        <ul className="list-disc pl-6 mb-6">
          <li>Send you requested financial notifications</li>
          <li>Maintain and improve our service</li>
          <li>Comply with legal obligations</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
        <p className="mb-6">We implement appropriate security measures to protect your personal information. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.</p>

        <h2 className="text-2xl font-semibold mb-4">5. Data Sharing</h2>
        <p className="mb-2">We do not sell, trade, or otherwise transfer your personal information to third parties. The only exceptions are:</p>
        <ul className="list-disc pl-6 mb-6">
          <li>Trusted partners who assist us in operating our service (like Plaid for banking connections)</li>
          <li>Legal requirements</li>
          <li>Protection of our rights and safety</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">6. Your Rights</h2>
        <p className="mb-2">You have the right to:</p>
        <ul className="list-disc pl-6 mb-6">
          <li>Access your personal data</li>
          <li>Correct inaccurate data</li>
          <li>Request deletion of your data</li>
          <li>Opt-out of communications</li>
          <li>Review and control your notification preferences</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">7. Opt-in/Opt-out</h2>
        <p className="mb-2">All notifications are strictly opt-in. You can:</p>
        <ul className="list-disc pl-6 mb-6">
          <li>Choose which accounts to monitor</li>
          <li>Select notification preferences</li>
          <li>Opt-out at any time by:
            <ul className="list-disc pl-6 mt-2">
              <li>Using the settings in your account</li>
              <li>Contacting us directly</li>
              <li>Replying STOP to any message</li>
            </ul>
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">8. Third-Party Services</h2>
        <p className="mb-2">We use trusted third-party services:</p>
        <ul className="list-disc pl-6 mb-6">
          <li>Plaid for secure banking connections</li>
          <li>Twilio for message delivery</li>
        </ul>
        <p className="mb-6">Each has their own privacy policies which we encourage you to review.</p>

        <h2 className="text-2xl font-semibold mb-4">9. Liability Limitation</h2>
        <p className="mb-2">While we strive for accuracy and reliability, we provide this service "as is" without warranties. We are not liable for:</p>
        <ul className="list-disc pl-6 mb-6">
          <li>Banking data inaccuracies</li>
          <li>Delayed or failed notifications</li>
          <li>Decisions made based on our notifications</li>
          <li>Service interruptions</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">10. Changes to Privacy Policy</h2>
        <p className="mb-6">We may update this privacy policy periodically. Changes will be posted on this page with an updated revision date.</p>

        <h2 className="text-2xl font-semibold mb-4">11. Contact Us</h2>
        <p className="mb-2">For privacy-related questions or to exercise your rights, contact us at:</p>
        <ul className="list-disc pl-6 mb-6">
          <li>Email: info@SpendoTracker.com</li>
          <li>Website: SpendoTracker.com</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">12. Consent</h2>
        <p className="mb-6">By using our service, you consent to this privacy policy. If you disagree with any terms, please discontinue use of our service.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;