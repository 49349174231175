import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { readBudget } from '../network/budget';
import { useUser } from '../store/UserContext';

export default function Budget() {
  const user = useUser()
  const { budget_id } = useParams(); // Get the budget ID from the URL
  const [budget, setBudget] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadBudget = async () => {
      try {
        const data = await readBudget({ user_id: user.id, token: user.token, budget_id });
        setBudget({ ...data });
      } catch (error) {
        console.error('Error fetching budget:', error);
      } finally {
        setLoading(false);
      }
    };

    loadBudget();
  }, [budget_id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!budget) {
    return <div>Budget not found.</div>;
  }

  return (
    <div className="px-4 pt-8 sm:px-6 lg:px-8">
      <h1 className="text-xl font-semibold text-gray-900">{budget.name}</h1>
      <p className="mt-2 text-sm text-gray-700">
        Spending Limit: ${budget.spendingLimit}
      </p>

      {/* Display recipients */}
      <section className="mt-6">
        <h2 className="text-lg font-medium text-gray-900">Recipients</h2>
        {budget.recipients && budget.recipients.length > 0 ? (
          <ul className="mt-2">
            {budget.recipients.map((recipient) => (
              <li key={recipient._id} className="text-sm text-gray-700">
                {recipient.name} - {recipient.phoneNumber}
              </li>
            ))}
          </ul>
        ) : (
          <p className="mt-2 text-sm text-gray-500">No recipients assigned.</p>
        )}
      </section>

      {/* Display accounts */}
      <section className="mt-6">
        <h2 className="text-lg font-medium text-gray-900">Accounts</h2>
        {budget.accounts && budget.accounts.length > 0 ? (
          <ul className="mt-2">
            {budget.accounts.map((account) => (
              <li key={account.account_id} className="text-sm text-gray-700">
                {account.name} ({account.bank_name}) - Ending in {account.mask}
              </li>
            ))}
          </ul>
        ) : (
          <p className="mt-2 text-sm text-gray-500">No accounts added.</p>
        )}
      </section>

      {/* You can add more sections to display additional budget information */}
    </div>
  );
}
