import { Routes, Route } from 'react-router-dom'
import { useUser } from "../store/UserContext";


// middleware
import RequireAuth from '../middleware/RequireAuth'

// auth'd routes
import Dashboard from '../containers/Dashboard'
import Banks from '../containers/Banks'
import Budgets from '../containers/Budgets'
import Bank from '../components/Bank'
import Budget from '../containers/Budget'
import Navbar from './Navbar';

// unauth'd routes
import Welcome from '../containers/Welcome'
import Pricing from '../containers/Pricing'
import AuthForm from '../components/AuthForm'
import PrivacyPolicy from '../containers/PrivacyPolicy';
import ExampleOptIn from '../containers/ExampleOptIn';


// dogshit
import Notifications from '../containers/Notifications'
import Recipients from '../containers/Recipients'
import Testing from '../components/Testing'
import Account from '../components/Account'
import Settings from '../containers/Settings'
import AccountLink from '../components/AccountLink'
import AccountDetails from '../components/AccountDetails'
import NewBankForm from '../components/NewBankForm';

export default function Main() {
  const user = useUser()
  return (
    <>
      {user
        ? <>
          <RequireAuth>
            <Navbar />
            <Routes>
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/opt-in" element={<ExampleOptIn />} />
              <Route path="/users/:user_id">
                <Route index element={<Dashboard />} />
                <Route path="banks">
                  <Route index element={<Banks />} />
                  <Route path=":bank_id" element={<Bank />} />
                </Route>
                <Route path="budgets">
                  <Route index element={<Budgets />} />
                  <Route path=":budget_id" element={<Budget />} />
                </Route>
              </Route>

              {/* <Route exact path="/testing" element={
                <Testing />
              } />
              <Route exact path="/recipients" element={
                <Recipients />
              } />
              <Route exact path="/settings" element={
                <Settings />
              } />
              <Route exact path="/dashboard" element={
                <Dashboard />
              } />
              <Route exact path="/notifications" element={
                <Notifications />
              } />
              <Route exact path="/banks" element={
                <Banks />
              } />
              <Route exact path="/budgets" element={
                <Budgets />
              } />
              <Route exact path="/bank" element={
                <Bank />
              } />
              <Route exact path="/account" element={
                <Account />
              } />
              <Route exact path="/account/link" element={
                <AccountLink />
              } />
              <Route exact path="/account/:itemId" element={
                <AccountDetails />
              } /> */}

            </Routes>
          </RequireAuth>
        </>
        : <Routes>
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/signup" element={<AuthForm form="signup" buttonText="Sign Up!" heading="Sign up to get started" />} />
          <Route exact path="/signin" element={<AuthForm form="signin" buttonText="Sign In!" heading="Sign in to your account" />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/opt-in" element={<ExampleOptIn />} />
          <Route exact path="*" element={<Welcome />} />
        </Routes>

      }
    </>
  )
}
